const zh_CN = {
  platform: '佳沛电商数据分析平台',
  Forget_Password: '忘记密码',
  Log_In: '登录',
  Welcome: '欢迎,',
  Confirm: '确认',
  Product: '果子',
  View_original: '查看原帖',
  Trendpopularity_Fruit: '果子评论热度趋势',
  Trendpopularity_Fruit_MOM: '果子评论热度环比趋势',
  Channelcomment_popularity: '渠道评论热度占比',
  Channelcomments_emotions: '渠道评论情感占比',
  TrendFruit_EmotionalHeat: '果子情感趋势',
  Attributenegative_emotion: '属性负向情感',
  Attributepositive_emotion: '属性正向情感',
  Trendattributeemotion_proportion: '属性情感占比趋势',
  ComparisonAttributeEmotion_Proportion: '属性情感占比对比',
  WordCloud: '词云',
  Data_Definition: '数据说明',
  WordCloudRanking: '词云排行榜',
}
export default zh_CN

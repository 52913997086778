import React, {
  useState,
  useEffect,
  createContext,
  lazy,
  Suspense,
} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom'

import { ConfigProvider, message } from 'antd'
import { IntlProvider } from 'react-intl'
import zh_CN from './static/locale/zh-CN'
import en_US from './static/locale/en-US'
// import zhCN from 'antd/locale/zh_CN'
import zh_cn from 'antd/locale/zh_CN'
import eng from 'antd/locale/en_US'
import { PrivateRoute } from './components/PrivateRoute/privateRoute'
import http from './utils/http'
import './App.css'
import 'dayjs/locale/zh-cn'
import moment from 'moment'
import 'moment/locale/zh-cn'

const Home = lazy(() => import('./components/home/home'))
const Login = lazy(() => import('./components/login/login'))
const ForgotPassword = lazy(() => import('./components/forgotPassword'))
const ModifyPassword = lazy(() => import('./components/modifyPassword'))

export const myContext = createContext({
  locale: '',
  languageChange: () => {},
  onLogin: () => {},
})

function App() {
  const [locale, setLocale] = useState('')
  const [auth, setAuth] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    let lang = ''
    if (localStorage.getItem('locale')) {
      lang = localStorage.getItem('locale')
    } else {
      lang = 'zh'
      localStorage.setItem('locale', lang)
    }
    // let lang = 'zh'
    // setLocale('zh')
    // moment.locale('zh-cn')
    // localStorage.setItem('locale', lang)
    if (lang === 'zh') {
      setLocale('zh')
      moment.locale('zh-cn')
    } else {
      setLocale('en')
      moment.locale('en')
    }
  }, [])

  const languageChange = (lang) => {
    setLocale(lang)
    localStorage.setItem('locale', lang)
  }

  // 登录
  const onLogin = (obj) => {
    if (obj) {
      http
        .post('/api/auth/login', obj)
        .then((res) => {
          setAuth(obj ? true : false)
          navigate('/home')
          localStorage.setItem('auth', obj ? true : false)
        })
        .catch((res) => {
          message.error(res.response.data.msg)
        })
    }
  }

  const contextVal = {
    languageChange,
    locale,
    onLogin,
  }

  const messages = {
    en: en_US,
    zh: zh_CN,
  }

  return (
    <IntlProvider
      locale={locale === 'zh' ? 'zh' : 'en'}
      messages={messages[locale]}
    >
      <ConfigProvider locale={locale === 'zh' ? zh_cn : eng}>
        <myContext.Provider value={contextVal}>
          <Suspense fallback={null}>
            <Routes>
              {/* <Route element={<PrivateRoute auth={auth} />}> */}
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/*" element={<Home />} />
              {/* </Route> */}
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/modify-password" element={<ModifyPassword />} />
            </Routes>
          </Suspense>
        </myContext.Provider>
      </ConfigProvider>
    </IntlProvider>
  )
}

export default App

const en_US = {
  platform: 'Zespri EC Review Analytical & Insights Platform',
  Forget_Password: 'Forget Password',
  Log_In: 'Log In',
  Welcome: 'Welcome,',
  Confirm: 'Confirm',
  Product: 'Product',
  View_original: 'View Consumer Verbatim',
  Trendpopularity_Fruit: 'Product Review Volume Trend',
  Trendpopularity_Fruit_MOM: 'Product Review Growth Trend',
  Channelcomment_popularity: 'Channel Review Volume %',
  Channelcomments_emotions: 'Channel Review Sentiment %',
  TrendFruit_EmotionalHeat: 'Product Review Sentiment Trend',
  Attributenegative_emotion: 'Attribute Negative Sentiment %',
  Attributepositive_emotion: 'Attribute Positive Sentiment %',
  Trendattributeemotion_proportion: 'Attribute Sentiment Trend',
  ComparisonAttributeEmotion_Proportion:
    'Attribute Sentiment Comparison by Product Types',
  WordCloud: 'Word Cloud',
  Data_Definition: 'Data Definition',
  WordCloudRanking: 'Word Cloud Ranking',
}
export default en_US
